import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import intro01 from '../images/pic02.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <h3>Inspire. Challenge. Support.</h3>

          <p>
            Der Kern von net.works sind unsere in Deutschland verteilten,
            persönlichen Treffen, an denen wir miteinander und voneinander
            lernen. Jedes Workshop Wochenende wird von einem oder mehreren
            Mitgliedern organisiert und umfasst ein konkretes Thema. Die Themen
            reichen von Workshops zur Führung von Mitarbeitern, über die
            Stärkung unseres sozialen Engagements, bis hin zu Methoden wie dem
            Agilen Arbeiten. Wenn mal unsere eigene Expertise nicht ausreicht,
            laden wir gerne externe Redner ein. Neben der fachlichen Vertiefung
            ist immer auch genug Zeit sich persönlich zu auszutauschen.
          </p>
          <span className="image main">
            <img src={intro01} alt="" />
          </span>
          <p>
            Über die persönlichen Treffen hinaus, pflegen wir monatliche
            Video-Calls um im Kontakt zu bleiben und regelmäßig eine Gruppe zu
            haben, in der wir private und berufliche Themen ansprechen können
            und andere um Rat fragen können. Zusätzlich organisieren wir immer
            mal wieder längere Reisen zum Skifahren oder Wandern, sodass wir uns
            noch besser kennen lernen und als Gruppe zusammenwachsen.
          </p>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt</h2>
          <form method="post" action="https://formspree.io/xknqgare">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Nachricht</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Absenden" className="special" />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
